@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;800&display=swap');

body {
  font-family: 'Nunito', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100vw;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --mainWhite: white;
  --white: white;
  --black: #001f3f;
  --mainBlack: #001f3f;

  --background: white;
  --cardbackground: rgba(0, 0, 0, 0.6);
  --cardbackground1: rgba(0, 0, 0, 0.1);
  --card: #ddd;
  --cardStroke: #2b3037;
  --darkGray: #2f353d;
  --gray: #808890;
  --blueSilver: #d2dcdf;
  --silver: #001f3f;
  --brandPressed: #172bb5;
  --red: #ab0303;
  --disabledText: #a1a9b0;

  --itemTitle: #001f3f;
  --categorytitle: #fdfdfd;

  --brand: #0074d9;
  --brandHover: #ab0303;
  --brandPressed: #172bb5;
  --mainHover: rgba(226, 226, 226, 0.514);
  --mainActive: rgba(169, 169, 169, 0.4);
  --icon: hsl(209, 61%, 16%);
  --transparent: transparent;
  --lightGrey: #dae2ec;
  --lighterGrey: #dee1e6;
  --darkGrey: #617d98;
  --itemTitleEvent:#ab0303;
  
  --mainCategoryViewAll:#ab0303;
  --mainCategoryViewAllHover:#0074d9;

  --modalBackground: rgba(10, 10, 10, 0.5);

  --clr-primary-5: #2caeba;
  --clr-primary-4: rgb(61, 145, 255);

  --clr-grey-3: hsl(209, 34%, 30%);

  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  /* // font size */
  --ff-primary: 'Roboto', sans-serif;
  --ff-secondary: 'Open Sans', sans-serif;
  --categorytitle: #fdfdfd;

  --font44: 44px;
  --font40: 40px;
  --font39: 39px;
  --font38: 38px;
  --font37: 37px;
  --font36: 36px;
  --font35: 35px;
  --font34: 34px;
  --font33: 33px;
  --font32: 32px;
  --font31: 31px;
  --font30: 30px;
  --font29: 29px;
  --font28: 28px;
  --font27: 27px;
  --font26: 26px;
  --font25: 25px;
  --font24: 24px;
  --font23: 23px;
  --font22: 22px;
  --font21: 21px;
  --font20: 20px;
  --font18: 18px;
  --font16: 16px;
  --font15: 15px;
  --font14: 14px;
  --font14: 14px;
  --font13: 13px;
  --font12: 12px;
  --font11: 11px;
  --font10: 10px;
  --font9: 9px;
  --font8: 8px;
  --font7: 7px;
  --font6: 6px;
  --font5: 5px;
}

.itemTitleHeadingColor {
  color: var(--itemTitle);
}
.itemLinksColor {
  color: var(--brand);
}
.font-800 {
  font-size: var(--font44);
  font-weight: 600;
  opacity: 1 !important;
  margin-bottom: 20px;
}

.font-700 {
  font-size: var(--font36);
}
.font-600 {
  font-size: var(--font24);
}

.font-500 {
  font-size: var(--font20);
  line-height: 20px;
}

.font-400 {
  font-size: var(--font18);
}

.font-300 {
  font-size: var(--font16);
}
.font-200 {
  font-size: var(--font14);
}
.font-100 {
  font-size: var(--font12);
}
.font-000 {
  font-size: var(--font11);
}

h2 {
  font-size: var(--font24);
  margin-bottom: 15px;
  display: block;
  /* text-align: center; */
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 20px;
}
h1,
h3 {
  font-size: var(--font36);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 400;
  padding-top: 20px;
}

h2::after,
h1::after {
  content: '';
  width: 120px;
  height: 4px;
  top: -1rem;
  display: block;
  background-color: var(--brand);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

/* Editor Choice background */
.categoryItemEditorChoice {
  background-color: var(--white);
  height: 250px;
  min-height: 250px;
  max-height: 250px;
}
.categoryItem_no_margin {
  margin: 0 !important;
}
/* modal */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 90;
  background-color: var(--cardbackground1);
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
}
.modal > h2 {
  padding: 20px;
  margin: 0;
  color: var(--brand);
  /* border-bottom: 1px solid #555; */
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}
.copy-popup {
  background: var(--mainBlack);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  transition: transform 0.5s ease;
  transform: translateY(-2rem);
  min-width: 60%;
  min-height: 40vh;
}
/* RMP CONTAINER */
#rmpPlayer {
  /* height: 100vh !important; */
  /* width: 100vw !important; */
}
.addSeecondary ul {
  transform: none !important;
}
#rmpLivePlayer {
  /* height: 90vh !important;
  width: 100vw !important; */
}

.rmpContainer {
  /* height: 100vh !important;
  width: 100vw !important; */
}
.bannerImage{
  background: linear-gradient(250deg,var(--brandHover) 10%,black 100.47%);
}


button {
  padding: 10px 56px;
  text-decoration: none;
  border: none;
  font-size: var(--font16);
  border-radius: 12px;
}
.itemTitleHeading{
  color: var(--brand);

}
button:hover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
button:disabled {
  background: var(--gray);
  color: var(--blueSilver);
  cursor: not-allowed;
}
button:disabled:hover {
  background: var(--gray);
  color: var(--blueSilver);
  cursor: not-allowed;
}

/* changing right and left button in banner color */
/* These are not banner carousel */
.carousel.carousel-slider .control-arrow:hover {
  color: var(--brand) !important;
  background: rgba(0, 0, 0, 0.8) !important;
}

.carousel .slide {
  padding: 0 20px;
}

.hero-container {
  width: 95%;
  margin: 0 auto;
}

/* // image wrapper for aspect-ratio 16/9
 */
.image_wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.image_wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.homePageContainer {
  width: 94%;
  margin: 0 0 0 6%;
}
/* media Queeries */
@media (max-width: 850px) {
  .font-800 {
    font-size: var(--font24);
  }
  .font-700 {
    font-size: var(--font22);
  }
  .font-600 {
    font-size: var(--font20);
  }

  .font-500 {
    font-size: var(--font16);
  }

  .font-400 {
    font-size: var(--font14);
  }

  .font-300 {
    font-size: var(--font13);
  }
  .font-200 {
    font-size: var(--font10);
  }
  .hero-container {
    width: 100%;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px) !important;
  }
}
@media (max-width: 700px) {
  .font-800 {
    font-size: var(--font20);
  }
  .font-700 {
    font-size: var(--font16);
  }
  .font-600 {
    font-size: var(--font14);
  }

  .font-500 {
    font-size: var(--font13);
  }
}
/* media Queeries */
@media (max-width: 600px) {
  .font-800 {
    font-size: var(--font24);
  }
  .font-700 {
    font-size: var(--font22);
  }
  .font-600 {
    font-size: var(--font20);
  }

  .font-500 {
    font-size: var(--font16);
  }

  .font-400 {
    font-size: var(--font14);
  }

  .font-300 {
    font-size: var(--font13);
  }
  .font-200 {
    font-size: var(--font10);
  }

  /* button {
    padding: 18px 20px;
  } */
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .overlay_blur {
  background-image: linear-gradient(
    270deg,
    #12161d 0%,
    rgba(18, 22, 29, 0.08) 100%
  );

  opacity: 1;

  z-index: 100000;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active)
  .playicon_overlay {
  display: none;
}

/* Banner carousel */

.carousel .control-prev.control-arrow {
  left: 30px !important;
}
.carousel .control-next.control-arrow {
  right: 30px !important;
}
.react-multiple-carousel__arrow {
  padding: inherit;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  top: 30%;
}
/* // banner slider custom button */
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  top: 50% !important;
  min-width: 43px !important;
  min-height: 43px !important;

  height: 43px !important;
  border-radius: 50% !important;
  background: rgba(58, 18, 18, 0.7) !important;
  opacity: 0.8 !important;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before,
.carousel .control-prev.control-arrow:before {
  margin: 0 !important;
  font-family: 'revicons' !important;
}
.carousel .control-next.control-arrow:before {
  content: '\e825' !important;
  border: none !important;
}
.carousel .control-prev.control-arrow:before {
  content: '\e824' !important;
  border: none !important;
}

/* // navigation mobile menu */
ul.main-menu {
  display: inline-flex;
  list-style: none;
  z-index: 7;
}

.menu-item a {
  position: relative;
  display: block;
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
}

.sub__menus__arrows {
  position: relative;
}
.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}
.languageSelectMobile {
  border-top: 1px solid var(--brand);
  width: 90%;
  margin: 10px auto;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 !important;
}
.action {
  display: flex;
}
.action > * + * {
  margin-left: 30px;
}

@media (min-width: 992px) {
  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a {
  color: var(--brandHover) !important;
}
.menu-item .sub__menus li:hover a {
  color: var(--brandHover) !important;
}
.navBarSiteLogoMobile {
  display: none;
}
.navBarSiteLogoMobile-lgScreen {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .navBarSiteLogo {
    display: none;
  }
  .navBarSiteLogoMobile-lgScreen {
    /* display: none; */
  }
  .navBarSiteLogoMobile {
    display: flex;
    margin: 20px 0;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .navBarSiteLogoMobileFooter {
    margin: 0;
  }
  .navBarSiteLogoMobileFooter{
    height: 75px;

  }
  .action {
    flex: 1;
  }
  .action .navBarSiteLogoMobile {
    flex: 0;
  }
  .navBarSiteLogoMobile-img {
    /* margin-left: -15px; */
    height: 38px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-nav .menubar__button:hover {
    color: var(--brand);
  }
  .main-nav .menu-item:hover > a {
    color: var(--brandHover) !important;
  }
  .main-nav .menubar__button {
    display: block !important;
    float: right;
    /* background-color: var(--brand); */
    /* color: #fff; */
    color: var(--brandHover);
    padding: 6px 7px;
    border-radius: 5px;
    margin: auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }
  .main-nav .menubar__button svg {
    font-size: 27px;
  }
  .main-nav ul.menuq2 {
    display: flex !important;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10007;
    background-color: var(--background);
    border-bottom: 3px solid var(--brand);
    right: 0;
    padding-bottom: 17px;
    padding-top: 57px;
    gap: 10px;
  }

  .main-nav ul.main-menu {
    display: none;
  }
  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .sub__menus__Active2 {
    display: block !important;
  }
  .menus__categorysss {
    display: none;
  }
  .menus__categorysss2 {
    display: none;
  }
  .menu-item .sub__menus__full a {
    text-align: left;
  }
  .ratingElement {
    width: 40px;
  }
  .action > * + * {
    margin-left: 15px;
  }
}
@media (max-width: 550px) {
  .ratingElement {
    width: 30px;
  }
  .carousel .slide {
    padding: 0;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(6% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(6% + 1px) !important;
  }
  .navBarSiteLogoMobile-img {
    margin-left: 0px;
    height: 30px;
  }
}

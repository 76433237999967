.Events_main_info {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    justify-content: space-between;
    gap: 10px;
  }
  

  .eventsItem1{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 200px));
    align-items: center;
    column-gap: 30px;
    row-gap: 20px;

  }

  .eventsItem1_card{
      background-color: var(--card);
  }
.categoriesDropdown {
    flex-direction: column;
    position: absolute;
    /* max-height: 15rem;
    height: 15rem; */
    background: var(--brandHover);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1rem;
    /* border-radius: 0.25rem; */
    z-index: 100000000000;
    color: var(--mainWhite);
    width: 200px;
    justify-content: center;
    align-items: center;
    /* padding-top: 200px; */
    padding-top: 0;
    margin-top: 0;
    
    /* left: 10%; */
  }
  .categoriesDropdown > * + * {
    /* margin-top: 10px; */
  }
  .categoriesDropdown a {
    text-decoration: none;
    color: var(--mainWhite) !important;
    padding: 10px !important;
  }
  .categoriesDropdown a:hover {
    text-decoration: none;
    color: var(--mainWhite);
    background-color: var(--mainBlack);
  }

  .container{
      /* position: relative !important; */
  }



  @media (max-width:990px) {
    .categoriesDropdown {
        
        
        left: 10%;
      }
  }
.ContactUs {
  background-color: #ddd;
  width: 100%;
  margin: 0 auto;
 
}
.ContactUsPrimary {
  display: flex;
  width: 80%;
  height: 100vh;
  justify-content: center;
  /* align-items: center; */
  margin: 0 auto;
  flex-direction: column;
  gap: 50px;
}

h4{
  text-align: center;
  color: var(--brandHover);}
/* .ContactUsPrimary > * + * {
  margin-left: 50px;
} */
.ContactUs_image {
  /* flex: 0.8; */

  display: flex;
  width: 50%;
  margin: 0 auto;
}
.ContactUs_image img {
  height: 100%;
  width: 100%;
}
.ContactUs_description {
  flex: 1;
  color: var(--mainBlack);
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  flex-direction: column;
}
.ContactUs_description_Primary {
  /* width: 80%; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ContactUs_description h4 {
  padding: 0 60px;
  color: var(--brandHover);
}
/* .ContactUs_description_Primary > * + * {
  margin-top: 50px;
} */

.ContactUs_description_email_button {
  background-color: var(--brandHover);
  text-decoration: none;
  color: var(--white);
  padding: 10px 56px;
  border: none;
  font-size: var(--font16);
  border-radius: 12px;
}
.ContactUs_description_email_button:hover {
  background-color: var(--brand);
}
.ContactUs_description_address_info,
.ContactUs_description_phone,
.ContactUs_description_email {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 60px;
}
.ContactUs_description_address_info > * + * {
  margin-top: 5px;
}
.ContactUs_description_address_info_title {
  margin-bottom: 10px !important;
  color: var(--brandHover);
}
/* media Queeries */
@media (max-width: 991px) {
  .ContactUs_image {
    /* display: none; */
  }
}


@media (max-width:550px) {
  .ContactUsPrimary {
   width: 95%;
   margin :0 auto;
  }
  .ContactUs_image {
    /* flex: 0.8; */
  
   width: 100%;
  }
  
}
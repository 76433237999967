
.aboutUsImage{
    display: flex;
}
.aboutUsImage img{
    max-width: 650px;
    margin: 0 auto;
    width: 650px;

    justify-content: center;

 
}

@media (max-width:650px) {
    .aboutUsImage img{
        width: 100%;
    }
    
}
/* 
.searchRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}
.resultButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
} */
.resultsAmount {
  align-self: center;
  margin-top: 1%;
}

.searchRowNewOrAlphabetContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}

.searchResultBTN {
  color: var(--white);
  background: var(--gray);
  cursor: pointer;
  margin-left: 10px;
}
.searchResultBTN:hover,
.searchResultBTNactive:hover {
  color: var(--brand);
  background: var(--brandHover);
}
.form_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  /*gap: 10px; stupid safari */
}

.searchUpper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.searchResultBTNactive {
  color: var(--white);
  cursor: pointer;
  background: var(--brand);
  margin-left: 10px;
}

.searchLabel {
  margin-bottom: 0.25rem;
}

.searchCheckbox {
  margin-right: 0.25rem;
}

.searchCheckboxLabel {
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 1%;
  white-space: nowrap;
}

.searchSelectCategory {
  padding: 0.25rem;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  color: var(--mainBlack);
  width: 60%;
  margin: 0 auto;
  padding: 20px 40px;
}
.row {
  display: flex;
  color: var(--mainBlack);
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}

.displayFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchColumn {
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  width: 100%;
}

.bottomContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.searchButton {
  color: var(--white);
  background-color: var(--brandHover);
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}

.FaIcon {
  margin-right: 5px;
}

.searchButton:hover {
  background-color: var(--brand);
}

.searchField {
  padding: 18px 0;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  text-align: center;
  border-radius: 12px;
}

.searchRow {
  margin-bottom: 1%;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
}
.search_form_label {
  display: flex;
}
#searchForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.searchTitle {
  margin-bottom: 2rem;
}

.resultsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
  align-items: center;
  justify-content: center;
  grid-column-gap: 30px;
  column-gap: 30px;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-top: 1%;
}
@media (max-width: 1180px) {
  .searchRow {
    width: 100%;
  }
  .search_form_label {
    margin-bottom: 2%;
  }
  .searchRowNewOrAlphabetContainer {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .searchContainer {
    width: 95%;
    padding: 20px 20px;
  }
}
@media (max-width: 700px) {
  .searchButton,
  .searchResultBTN,
  .searchResultBTNactive {
    padding: 10px 20px !important;
  }
}

@media (max-width: 550px) {
  .searchContainer {
    padding: 5px;
  }
  .searchRow {
    width: 95%;
    padding: 0px;
    margin: 0 auto;
  }
  .row {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
}

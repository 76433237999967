.selectedPackage{
    background-color: whitesmoke;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.selectedPackage >*+*{
    margin-top: 20px;
}
 

.selectedPackagePrimary{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.selectedPackagePrimary >*+*{
  flex: 1;
  margin-right: 40px;
}
.selectedPackageList_name{
    flex: 4;
}
.selectedPackagePrimaryHeading{
    font-weight: 700;
}


@media (max-width:550px) {
    .selectedPackagePrimary{
       flex-direction: column;
    }
    .selectedPackagePrimary >*+*{
        margin-right: 0;
        margin-top: 20px;
      }
    .selectedPackage{
       flex-direction: row;
    }
    .selectedPackageList_name{
        flex: 10;
    }
    .selectedPackage >*+*{
        margin-top: 0;

    }

     
    
    
}